html {
    background: #ffffff !important;
}

body {
    margin: 0px !important;
    font-family: var(--main-font);
    font-size: 15px;
    font-weight: lighter;
}

a {
    text-decoration: none;
    display: inline-block;
}

ul {
    list-style: none;
    margin: 0 0 1em;
    padding-inline-start: 0px;
}

.container-benefits{
    margin:16px -30px 0;
    border-top: 1px solid #ddd;
    background-color: #f6f6f6;
}

.container-benefits-standalone{
    margin: 0;
    border-top: 1px solid #ddd;
    background-color: #f6f6f6;
}


.benefits-list {
    text-align: left;
}

.benefits-list i{
    color:#c29e51;
    margin: 0 8px 0 -20px;
    width: 1em;
    left: 0;
}
.benefits-list strong{
    color: #ccc;
}
.benefits-list .include-cant{
    color: #c29e51;
}
.benefits-list .include {
    color: #000;
}

.benefits-list .not-include,
.benefits-list .not-include i {
    color: #ccc ;
}

.benefits-list li {
    display: flex;
    align-items: center;
    padding: 0px 15px 5px 3em;
    min-height: 60px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Libre Franklin',sans-serif;
}

.component-card .subtext,
.card .subtext{
    font-family: 'Libre Franklin',sans-serif;
    font-weight: 400;
    font-size: 14px;
}
/*ul li::before {
    content: "\02C3";
    color: var(--primary);
    display: inline-block; 
    width: 2em;
    height: 12px;
    vertical-align: middle;
}*/

button:disabled {
    border: none !important;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

hr {
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
}

h1, h2, h6 {
    margin-block-start: 0px;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    border: 1px solid var(--secondary);
}

button:disabled:hover {
    background-color: var(--card-bg);
    border: 1px solid var(--secondary);
    color: var(--secondary);
}

button:disabled img {
    height: 15px;
}

// Stripe
.Form {
    animation: fade 200ms ease-out;
  }

.FormGroup {
    margin: 0 0px 20px;
    padding: 0;
    border-style: none;
    background-color: var(--card-bg);
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 var(--link);
    border-radius: 4px;
  }

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid var(--link);
  }
  
  .FormRow:first-child {
    border-top: none;
  }

  .FormRowLabel {
    width: 15%;
    min-width: 100px;
    padding: 11px 0;
    color: var(--secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'proxima-nova', sans-serif;
  }

  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: var(--secondary);
    background-color: transparent;
    animation: 1ms void-animation-out;
  }
  
  .FormRowInput::placeholder {
    color: var(--light);
    font-family: 'proxima-nova', sans-serif;
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

// Main

.header {
    background-color: var(--card-bg);
    margin-bottom: 0px !important;
}

.logo {
    height: 70px;
}

.flexer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.flexer-spaced {
    justify-content: space-between;
}

.flexer-vcenter {
    align-items: center;
}

.flexer-nowrap {
    flex-wrap: nowrap !important;
    overflow: scroll;
}

.flexer-2 {
    flex: 0 0 15%;
}

.flexer-card {
    flex: 0 0 18%;
}

.flexer-3 {
    flex: 0 0 30%;
}

.flexer-4 {
    flex: 0 0 40%;
}

.flexer-5 {
    flex: 0 0 49%;
}

.flexer-6 {
    flex: 0 0 60%;
}

.flexer-7 {
    flex: 0 0 70%;
}

.flexer-8 {
    flex: 0 0 80%;
}

.content-card {
    background-color: #f9f5ed;
    width: -moz-calc(100% - 120px);
    width: -webkit-calc(100% - 120px);
    width: calc(100% - 120px);
    padding: 30px;
}

.inner-card {
    background-color: white;
    padding: 30px 0px;
}

.splitted-card {
    background: #FFFFFF;
    margin: 30px auto;
    width: 90%;
    border-radius: 3px;
}

.catalogue-card {
    background-color: white;
    width: 24%;
    margin: 30px 0.6%;
}

.catalogue-card:first-child, .home-card:first-child {
    margin-left: 0px;
}

.catalogue-card:last-child, .home-card:last-child {
    margin-right: 0px;
}

.catalogue-card__text {
    padding: 20px 30px;
}

.home-card {
    background-color: white;
    width: 32%;
    margin: 30px 1%;
}


.huge-letters {
    font-size: 38px;
    letter-spacing: 0px;
    font-weight: 400;
}

.title {
    font-size: 26px;
    font-weight: bold;
}

.subtitle {
    font-size: 24px;
    font-weight: normal;
}

.text {
    font-size: 15px;
    font-weight: normal;
}

.subtext {
    font-size: 12px;
    font-weight: lighter;
}

.error {
    color: var(--error);
    font-size: 12px;
}

.link {
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.link:hover {
    color: var(--primary);
}

.caps {
    text-transform: uppercase;
}

.remaining-width {
    flex: 1 1 auto;
}

// Elements

.tab-menu {
    background-color: var(--card-bg);
    border-top: 1px solid var(--lines);
}

.tab-item {
    margin: 0px 15px;
    padding: 5px 10px;
    color: var(--primary);
    font-weight: normal;
    border-bottom: 2px solid var(--card-bg);
}

.tab-item:hover, .tab-active {
    border-bottom: 2px solid var(--primary);
}

.button {
    background-color: var(--link);
    border: 1px solid var(--link);
    padding: 10px 30px;
    font-size: 15px;
    cursor: pointer;
    height: fit-content;
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-align: center;
    width: auto;
    margin: 20px 0;
}

.button:hover {
    background-color: var(--card-bg);
    color: var(--link);
}

.current-button {
    background-color: var(--primary);
    padding: 10px 30px;
    font-size: 15px;
    height: fit-content;
    color: white;
    font-weight: bold;
}

.table-header th {
    color: var(--secondary);
    font-weight: 500;
}

.table-header th, .table-rows td {
    padding: 15px 5px;
    border-bottom: 1px solid #EBEBEB;
}

.input-field {
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 20px;
}

.input-field__label {
    font-size: 13px;
    color: var(--text);
    font-weight: normal;
}

.input-field__input {
    border: none;
    border-bottom: 1px solid var(--lines);
    margin-top: 5px;
    width: 100%;
    font-size: 15px;
    color: var(--secondary);
    background: var(--card-bg);
}

.input-field__checkbox {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
    outline: auto;
    border-style: solid;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.component-card{
    border-color: rgba(193, 156, 81, 0.3);
    border-style: solid;
    border-width: 1px;
    background: var(--card-bg);
    margin: 10px;
    padding: 20px;
}


.card {
    height: fit-content;
    background: var(--card-bg);
    box-shadow: -4px 0px 5px 0px rgb(0 0 0 / 18%);
    -webkit-box-shadow: -4px 0px 5px 0px rgb(0 0 0 / 18%);
    margin-bottom: 20px !important;
}

.plans-card-active {
    background: #e7eef2;
    border-color: rgba(0, 76, 117, 0.3);
}

.secondary-card {
    background: var(--secondary-bg);
    padding: 20px;
    border-radius: 4px;
}

.even {
    background-color: #f9f5ed;
}

// Registration
.reg-card {
    background: var(--card-bg);
    margin: 50px auto;
    box-shadow: -1px 0px 5px #ccc;
}

// Pedigree Tree

.tree {
    position: relative;
}

.tree__col[col="1"] .tree__row {
    height: 50%;
    min-width: 150px;
}

.tree__col[col="2"] .tree__row {
    height: 25%;
    min-width: 150px;
}

.tree__col[col="3"] .tree__row {
    height: 12.5%;
    min-width: 150px;
}

.tree__col[col="4"] .tree__row {
    height: 6.25%;
    min-width: 150px;
}

.tree__col[col="5"] {
    min-width: 150px;
}

.tree__line {
    background-color: #F7F7F7;
    height: .09091rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.tree__line[data-num='1'] {
    top: 6.25%;
    width: 40%;
}

.tree__line[data-num='2'] {
    top: 12.5%;
    width: 60%;
}

.tree__line[data-num='3'] {
    top: 18.75%;
    width: 40%;
}

.tree__line[data-num='4'] {
    top: 25%;
    width: 80%;
}

.tree__line[data-num='5'] {
    top: 31.25%;
    width: 40%;
}

.tree__line[data-num='6'] {
    top: 37.5%;
    width: 60%;
}

.tree__line[data-num='7'] {
    top: 43.75%;
    width: 40%;
}

.tree__line[data-num='8'] {
    top: 50%;
    width: 100%;
}

.tree__line[data-num='9'] {
    top: 56.25%;
    width: 40%;
}

.tree__line[data-num='10'] {
    top: 62.5%;
    width: 60%;
}

.tree__line[data-num='11'] {
    top: 68.75%;
    width: 40%;
}

.tree__line[data-num='12'] {
    top: 75%;
    width: 80%;
}

.tree__line[data-num='13'] {
    top: 81.25%;
    width: 40%;
}

.tree__line[data-num='14'] {
    top: 87.5%;
    width: 60%;
}

.tree__line[data-num='15'] {
    top: 93.75%;
    width: 40%;
}

.tree__input {
    background: #f9f5ed;
    border: none;
    width: 100%;
    padding: 10px 10px;
    margin: 3px;
}

.chip {
    background-color: var(--link) !important;
    margin-bottom: 0px
}

.steps {
    background-color: var(--link);
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.steps p {
    color: white;
    font-size: 18px;
    font-weight: bolder;
}

.left-menu__item {
    border-bottom: 2px solid white;
}

.left-menu__item:hover, .left-menu__active-item {
    border-bottom: 2px solid var(--link);
}

.file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__box {
    padding: 30px;
    border: 2px dotted var(--link);
}

.sidebar {
    width: 60px;
    height: 100%;
    background-color:  var(--link);
}

.sidebar__item {
    padding: 20px;
    text-align: center;
}

.sidebar__item:hover {
    background-color: #685f4a;
    cursor: pointer;
}

.sidebar__item:hover .bubble {
    visibility: initial;
}

.sidebar__item__active {
    background-color: #685f4a !important;
}

.bubble {
    background-color: var(--link);
    border-radius: 3px;
    position: absolute;
    left: 50px;
    padding: 0px 10px;
    top: 30%;
    height: 40%;
    display: table;
    width: max-content;
    visibility: hidden;
    z-index: 9;
}

.bubble:before {
    content: " ";
    position: absolute;
    left: -5px;
    border-left: 5px solid var(--link);
    border-bottom: 5px solid var(--link);
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    transform: rotate(45deg); 
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 27%;
}

.bubble__text {
    display: table-cell;
    vertical-align: middle;
}

// Modifiers

.is-relative {
    position: relative;
}

.center {
    margin: 0px auto !important;
    text-align: center !important;
    justify-content: center;
}

.right {
    margin-right: 0px !important;
    text-align: right !important;
    justify-content: flex-end !important;
}

.left {
    margin-left: 0px !important;
    text-align: left !important;
    justify-content: flex-start !important;
}

.is-warning {
    color: var(--warning);
}

.light-bold {
    font-weight: 700;
}

.is-bold {
    font-weight: 900;
}

.is-cross-out {
    text-decoration: line-through;
}

.is-light-weight {
    font-weight: 300;
}

.is-blue {
    color: var(--primary);
}

.is-black {
    color: var(--secondary);
}

.is-light {
    color: var(--light);
}

.is-gold {
    color: var(--link);
}

.is-white {
    color: white;
}

.line-margin-0 {
    margin: 0px !important;
}

.mx-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.mx-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mx-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mx-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.px-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.max-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.max-screen__bottom {
    flex: 1 1 auto;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.two-third-width {
    width: 66%;
}

.half-width {
    width: 50%;
}

.one-third-width {
    width: 33%;
}

.one-fourth-width {
    width: 25%;
}

.one-fifth-width {
    width: 20%;
}

.one-sixth-width {
    width: 16.6%;
}

.screen-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
}

.modal-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
}

.is-primary {
    color: var(--link);
}

.is-primary {
    color: var(--primary);
}

.cursor {
    cursor: pointer;
}

.underline {
    text-decoration: underline;
}

.sidebar__item__1 {
    background-color: #91783f;
}

.sidebar__item__2 {
    background-color: #a58847;
}

.sidebar__item__3 {
    background-color: #af8f4a;
}

.sidebar__item__4 {
    background-color: #b8964e;
}

.sidebar__item__5 {
    background-color: #c19e51;
}

.textover-topleft {
    position: absolute;
    top: 0;
    left: 0;
}

.textover-bottomleft {
    position: absolute;
    bottom: 0;
    left: 0;
}

// Modal

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.Modal__container {
    background-color: var(--card-bg);
    padding: 40px;
    width: 30%;
    border-radius: 5px;
    overflow: auto;
    max-height: 80%;
    }

.Modal__close {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
}

.left-menu {
    border-right: 1px solid var(--lines);
    margin: -1px;
}

.modal-icon {
    background: var(--link);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 10px;
    font-size: 20px;
}

.modal-icon-small {
    background: var(--link);
    color: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    vertical-align: middle;
    padding: 2px;
    font-size: 10px;
}

@media screen and (max-width: 1024px) {

    table {
        display: block;
        overflow-x: scroll;
        white-space: nowrap;
        scroll-margin: 0px;
    }

    .full-mobile {
        width: 100% !important;
    }

    .flexer {
        flex-wrap: wrap;
    }

    .flexer-mobile {
        display: block !important;
    }

    .screen-center {
        position: initial;
        transform: none;
    }

    .center-mobile {
        margin: 0px auto !important;
        text-align: center !important;
        justify-content: center;
    }

    .banner-mobile {
        background-color: var(--primary);
    }

    .banner-mobile img {
        display: none;
    }

    .banner-mobile div {
        position: initial;
        margin-top: 0px;
        padding: 15px 0px;
    }

    .home-card {
        width: 100%;
    }

    .huge-letters {
        font-size: 20px;
        word-wrap: initial;
    }

    .line-margin-0-mobile {
        margin: 0px !important; 
    }

    .content-card {
        width: 100%;
        padding: 30px 0px;
    }

    .reg-card{
        margin: auto;
    }

    .catalogue-card {
        width: 100%;
    }

    .filter-mobile {
        filter: brightness(0.5);
    }

    .sidebar {
        display: flex;
        width: 100%;
        height: fit-content;
    }

    .bubble {
        display: none;
    }

    .left-menu {
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }

    .remaining-mobile {
        flex: 1 1 auto;
    }
}

.tabs {
    margin-bottom: 20px;
}

.activeTab {
    background-color: var(--link);
    border: 1px solid var(--link);
    padding: 10px 30px;
    font-size: 15px;
    cursor: pointer;
    height: fit-content;
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-align: center;
}
.tab {
    background-color: var(--secondary-bg);
    border: 1px solid var(--secondary-bg);
    padding: 10px 30px;
    font-size: 15px;
    cursor: pointer;
    height: fit-content;
    text-decoration: none;
    color: black;
    font-weight: bold;
    text-align: center;
}
.frequency-container {
    margin: 10px 0;
}

.frequency-select {
    background-color: var(--link);
    border: 1px solid var(--link);
    padding: 5px 15px;
    font-size: 15px;
    cursor: pointer;
    height: fit-content;
    text-decoration: none;
    color: white;
    font-weight: bold;
}
.frequency-select:focus-visible {
    outline: none;
}

.price {
    font-size: 19px;
}
.price strong{
    font-size: 34px;
}

.title-plan h4{
    padding: 17px 0;
    font-size: 18px;
    margin: 0 0 10px 0;
    font-family: 'Libre Franklin',sans-serif;
}

.is-basic-plan {
    background-color: #99b7c8;
}

.is-standard-plan {
    background-color: #739db3;
}

.is-premium-plan {
    background-color: #4c819e;
}

.is-super-plan {
    background-color: #26678a;
}

.is-power-plan {
    background-color: #004c75;
}

.includes-title hr{
    margin: 17px auto 10px;
    border-top: 5px solid #c29e51;
    width: 80px;
}

.includes-title h6{
    color: #c29e51;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 11px;
    font-family: 'Libre Franklin',sans-serif;
    line-height:1.5;
}

.component-card .button,
.card .button{
    margin: 0 !important;
}
.component-card .container-button
.card .container-button{
    margin-top: -16px;
}

.is-button-basic-plan{
    background-color: #fcfaf6;
}
.is-button-power-plan{
    background-color: #ebdfc6;
}
.is-button-premium-plan{
    background-color:#f4edde;
}
.is-button-standard-plan{
    background-color: #f8f3ea;
}
.is-button-super-plan{
    background-color: #efe6d2;
}

.component-card .flexer-2,
.card .flexer-2{
    flex: 0 0 18% !important;
}

@media only screen and (max-width: 1225px){
    .flexer-card{
        flex: 0 0 33%;
    }
    .benefits-list li {
        min-height: 80px;
    }
}


@media only screen and (max-width: 655px){
    .flexer-card{
        flex: 0 0 50%;
    }
    .benefits-list li {
        min-height: 75px;
    }
}

.rule-with-text {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    color: #4b4b4b;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
}

.rule-with-text:after,
.rule-with-text:before {
    content: "";
    -webkit-box-flex: 1;
    flex: 1;
    border-bottom: 1px solid #eaeaea;
}

.rule-with-text:not(:empty):before {
    margin-right: 0.5em;
}

.rule-with-text:not(:empty):after {
    margin-left: 0.5em;
}

.social-buttons-separator {
    margin-bottom: 10px;
}

.custom-social-button,
.kep-login-facebook{
    width: 65%;
    margin: 5px 0;
    font-size: 15px !important;
    font-weight: 700;
}

.google-login-button{
    margin: auto;
    display: flex;
    justify-content: center;
}

.kep-login-facebook{
    text-transform: none !important;
    padding: calc(.34435vw + 4.38843px) calc(.34435vw + 10.38843px) !important;
}
.kep-login-facebook .fa{
    margin-right: 11px;
}

.social-buttons-container .error{
    margin: 0;
}

.help-icon {
    padding-left: 10px;
    color: #C19C51;
}