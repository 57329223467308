body {
    --link: #C19C51;
    --primary: #004C75;
    --secondary: #272727;
    --light: #737373;
    --main-font: 'Libre Franklin', sans-serif;
    --text: rgba(39, 39, 39, 0.7);
    --lines: rgba(0, 76, 117, 0.10);
    --card-bg: white;
    --secondary-bg: #F4F6F9;
    --shadow: rgba(0, 0, 0, 0.10);
    --error: red;
    --warning: #d7df50;
}